import { NoteResult } from "common/note/dto/NoteResult";

export function noteInList(
  note: NoteResult,
  noteList: NoteResult[] | null
): boolean {
  let result = false;
  if (
    typeof note !== "undefined" &&
    typeof noteList !== "undefined" &&
    noteList !== null &&
    noteList.find((item: NoteResult) => item.id === note.id)
  ) {
    result = true;
  }

  return result;
}
